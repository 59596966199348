import { doHttpsGet } from "../util/request";
import { doHttpGet } from "../util/httpRequest";
import { createIntegerRangeSet } from "../util/factory";

// import { updateBanPick } from '../services/banPickLiveService';



const devApiKey = '03Db0IJhVv8rGB479IzIS93u6R7pFRPlD78fsli0';
const prodApiKey = 'g9Y4JY7Ouh9ZGNgNr3iuC8DUVoiSRyAA4kunVZqZ';
const prodHost = 'raw-stats-api.ewp.gg';
const devHost = 'localhost';

const getRawStatsEndpoint = (platformID, gameID, paginationToken) =>
    `/livestatsRaw/v1/platformGames/${platformID}_${gameID}/events${(paginationToken && `?paginationToken=${paginationToken}`) || ''}`

// const isProd = process.env.NODE_ENV === 'production';
const isProd = true;

const apiKey = isProd ? prodApiKey : devApiKey;
const hostname = isProd ? prodHost : devHost;

const ERRORS = { // enum
    CONNECTION: 'CONNECTION',
}

export const readGameEvents = (platformID, gameID, callbackFunction, delayMs, context, fieldCallbacks, simulatedCurrentTimeUnixTimestamp, errorCallback) => {
    let rateLimitPerSecond = 1;

    if (context) {
        console.log('continuing from previous context', context);
    }

    /* Example:
        context.processedSequenceIdsGroups = [
            [minIdInclusive, maxIdInclusive],
            [1,101], [103, 222], [223, 450],
        ]
    */
    const processedSequenceIdsSet = createIntegerRangeSet(context && context.processedSequenceIdsGroups);

    const missingEventTokens = [];

    let paginationToken = context && context.startPaginationToken;
    const timeoutPointer = { value: -1, value2: -1 };
    const delayedEvents = context.delayedEvents || {};
    if (context) {
        context.delayedEvents = delayedEvents;
    }

    processPages(platformID, gameID, paginationToken, missingEventTokens, delayMs, processedSequenceIdsSet, rateLimitPerSecond,
        callbackFunction, context, fieldCallbacks, simulatedCurrentTimeUnixTimestamp, timeoutPointer, delayedEvents, errorCallback).then(response => {
            if (response && response.error) {
                errorCallback && errorCallback(response.error);
            }
        });
    return () => {
        timeoutPointer.value && timeoutPointer.value !== -1 && clearTimeout(timeoutPointer.value);
        timeoutPointer.value = null;
        timeoutPointer.value2 && timeoutPointer.value2 !== -1 && clearTimeout(timeoutPointer.value2);
        timeoutPointer.value2 = null;
    }
};

const processDelayedEvents = (delayedEvents, currentTimestamp, delayMs, callbackFunction, fieldCallbacks) => {
    let event;
    // console.log('processing delayed events', Object.keys(delayedEvents).length);
    Object.keys(delayedEvents).forEach(key => {
        event = delayedEvents[key];
        // console.log('processing delayed event', event.rfc460Timestamp);
        const eventTimestamp = event.rfc460Timestamp && Date.parse(event.rfc460Timestamp);
        if (eventTimestamp <= (currentTimestamp - delayMs)) {
            // console.log('processing delayed event now', event.rfc460Timestamp, new Date(currentTimestamp));
            // console.log('processing delayed event', event.sequenceIndex, new Date(currentTimestamp - delayMs), event.rfc460Timestamp);
            processEvent(event, callbackFunction, fieldCallbacks);
            delete delayedEvents[key];
        }
    });
};

const processPages = async (platformID, gameID, paginationToken, missingEventTokens, delayMs, processedSequenceIdsSet, rateLimitPerSecond,
    callbackFunction, context, fieldCallbacks, currentTimestamp, timeoutPointer, delayedEvents, errorCallback) => {
    const startTime = Date.now();
    if (!delayedEvents) {
        delayedEvents = {};
    } else {
        processDelayedEvents(delayedEvents, currentTimestamp || Date.now(), delayMs, callbackFunction, fieldCallbacks);
    }

    let finished = false;
    if (!paginationToken && delayedEvents && Object.keys(delayedEvents).length > 0) {
        finished = true;
    } else {
        let page = null;
        let remainingTries = 3;
        while (page === null && remainingTries > 0) {
            remainingTries--;
            // console.log('remainingTries', remainingTries);
            try {
                if (isProd) {
                    page = await doHttpsGet(null, {
                        host: hostname,
                        path: getRawStatsEndpoint(platformID, gameID, paginationToken),
                        headers: {
                            'x-api-key': apiKey,
                        },
                    }, 3000);
                } else {
                    page = await doHttpGet(null, {
                        host: hostname,
                        path: getRawStatsEndpoint(platformID, gameID, paginationToken),
                        port: 5678,
                    }, 3000);
                }
            } catch (error) {
                console.log('error calling riot realtime api', error);
                page = null;
                if (remainingTries > 0) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
        }
        if (page === null) {
            console.log('returning connection error');
            return {
                error: ERRORS.CONNECTION,
            }
        }

        if (timeoutPointer.value === null) {
            return;
        }

        if (page && page.events && page.events.length > 0) {
            processEvents(processedSequenceIdsSet, page.events, callbackFunction, delayMs, fieldCallbacks, (currentTimestamp && (currentTimestamp + Date.now() - startTime)) || Date.now(), delayedEvents)
            if (page.events && page.events.length < 50) {
                rateLimitPerSecond = 1; // change to 1 per second for realtime.
            }
            if (!page.nextPageToken) {
                finished = true;
            }
            paginationToken = page.nextPageToken;
            // console.log('paginationToken', paginationToken);
        } else {
            rateLimitPerSecond = 1;
        }

        // if (page && page.missingEventsToken) {
        //     console.log('missing events, switching to missingEventsToken');
        //     paginationToken = page.missingEventsToken;
        //     finished = false;
        // } // no reason to process these since we don't care about about events anyway, at least not with our current events - stevenm 2020-01-27

        context.startPaginationToken = paginationToken;
        context.processedSequenceIdsGroups = processedSequenceIdsSet.getIntGroups();
    }

    if (!finished) { // game is over when paginationToken is gone.
        let waitTime = (1000 / rateLimitPerSecond) - (Date.now() - startTime);
        if (waitTime < 20) { // min wait time is 20ms
            waitTime = 20;
        }
        timeoutPointer.value = setTimeout(() => processPages(platformID, gameID, paginationToken, missingEventTokens, delayMs, processedSequenceIdsSet, rateLimitPerSecond,
            callbackFunction, context, fieldCallbacks, currentTimestamp && (currentTimestamp + (Date.now() - startTime)), timeoutPointer, delayedEvents, errorCallback).then(response => {
                if (response && response.error) {
                    errorCallback && errorCallback(response.error);
                }
            }), waitTime);
    } else {
        if (delayedEvents && Object.keys(delayedEvents).length > 0) {
            console.log('Game Ended, continuing to process the delayed events...');
            const job = () => {
                if (delayedEvents && Object.keys(delayedEvents).length > 0) {
                    if (timeoutPointer.value2 !== null) {
                        timeoutPointer.value2 = setTimeout(() => {
                            // console.log('processing delayed events');
                            processDelayedEvents(delayedEvents, (currentTimestamp + (Date.now() - startTime)) || Date.now(), delayMs, callbackFunction, fieldCallbacks);
                            job();
                        }, 200);
                    }
                } else {
                    console.log('Finished processing delayed events.');
                }
            };
            job();
        } else {
            console.log('Game Ended');
        }
    }
};

export const stateBasedEvents = ['champ_select']; // we defer these to only process the last one in a series.
const processEvents = (processedSequenceIdsSet, events, callbackFunction, delayMs, fieldCallbacks, currentTimestamp, delayedEvents) => {
    if (events) {
        let deferred = {};
        events.sort((a, b) => a.sequenceIndex - b.sequenceIndex);
        for (let i = 0; i < events.length; i++){
            const event = events[i];
            if (event) {
                // console.log("Event=>",event)
                if(!processedSequenceIdsSet.includes(event.sequenceIndex)){
                    const eventTimestamp = event.rfc460Timestamp && Date.parse(event.rfc460Timestamp);
                    // console.log('compare', new Date(eventTimestamp), new Date(currentTimestamp - delayMs));

                    // console.log('process INDEX', event.sequenceIndex);
                    if (!['game_info', 'pause_ended'].includes(event.rfc461Schema)) { // these events set gameTime so we always want to process them.
                        processedSequenceIdsSet.add(event.sequenceIndex);
                    }
                    // console.log('EVENT TYPE', event.rfc461Schema, event);
                    // console.log('current timestamp', currentTimestamp, new Date(currentTimestamp));
                    if (eventTimestamp > (currentTimestamp - delayMs)) {
                        // console.log('Skipping', event.sequenceIndex);
                        delayedEvents[`${event.sequenceIndex}`] = event;
                    } else if (stateBasedEvents.includes(event.rfc461Schema)) {
                        // console.log("champSelect", event);
                        deferred[event.rfc461Schema] = () => processEvent(event, callbackFunction, fieldCallbacks);
                    } else {
                        if (event.sequenceIndex === 0) {
                            // process all the negative sequenceIndex deferred before this event.
                            // eslint-disable-next-line
                            Object.keys(deferred).forEach(key => deferred[key]());
                            deferred = {};
                        }
                        processEvent(event, callbackFunction, fieldCallbacks);
                    }
                } else {
                    console.log('already processed', event.sequenceIndex);
                }
            }
        }
        Object.keys(deferred).forEach(key => deferred[key]());
    }
    // console.log('processed', processedSequenceIdsSet.getIntGroups());
};

const processEvent = (event, callback, fieldCallbacks) => {
    const sendData = {};
    const fields = [];
    const monsterTypes = ['dragon', 'riftHerald', 'baron', 'scuttleCrab'];
    const teamOne = {};
    const teamTwo = {};
    switch (event.rfc461Schema) {
        // case 'turret_plate_destroyed':
        //     fields.push(Object.keys(event));
        //     break;
        case 'epic_monster_kill':
            // if (!monsterTypes.includes(event.monsterType)) {
            //     return;
            // }
            // console.log('MONSTER KILL', event);
            fields.push('monsterType', 'dragonType', 'gameTime', 'killerTeamID', 'killer', 'position', 'assistants');
            break;
        case 'epic_monster_spawn':
            if (!monsterTypes.includes(event.monsterType)) {
                return;
            }
            fields.push('monsterType', 'gameTime', 'dragonType');
            break;
        case 'item_destroyed':
            if (![3513, 3514].includes(event.itemID)) { // herald item
                return;
            }
            fields.push('participantID', 'gameTime', 'itemID', 'rfc461Schema');
            break;
        case 'item_purchased':
        case 'item_sold':
        case 'item_undo':
            fields.push('participantID', 'gameTime', 'itemID', 'rfc461Schema');
            break;
        case 'queued_dragon_info':
            fields.push('dateUnix', 'nextDragonSpawnTime', 'nextDragonName');
            break;
        case 'champ_select': {
            // console.log('ABC champ_select', event)
            // updateBanPick(event);
            fields.push('pickTurn', 'gameState', 'bannedChampions');
            const convertPlayer = (player, index) => ({
                arrayIndex: index,
                // participantID: player.participantID,
                championID: player.championID, // this is non-zero after player has chosen a champion
                championName: null, // this will become set when champion is finalized, and we will set championID back to 0
                pickTurn: player.pickTurn,
                // Pick Mode : 0=> No picking, 1 => picking Time, 2=> Champion selected and confirmed
                pickMode: player.pickMode, // if he was the last person that picked, this = 1 I think
                summonerName: player.summonerName, // SG Reiya
                summonerInternalName: player.summonerInternalName, // sgreiya
                accountID: `${player.accountID}`,
                selectedSkinIndex: player.selectedSkinIndex,
                profileIconID: player.profileIconID,
                summonerID: player.summonerID,
                spell1: player.spell1,
                spell2: player.spell2,
            });
            event.teamOne && event.teamOne.forEach((player, index) => {
                teamOne[player.accountID] = convertPlayer(player, index);
            });
            event.teamTwo && event.teamTwo.forEach((player, index) => {
                teamTwo[player.accountID] = convertPlayer(player, index);
            });

            sendData.teamOne = teamOne;
            sendData.teamTwo = teamTwo;
            sendData.bannedChampions = event.bannedChampions;
        }
            break;
        case 'game_info': {
            const convertParticipant = (participant, index) => ({
                arrayIndex: index,
                participantID: participant.participantID,
                championName: participant.championName,
                championID: 0,
                teamID: participant.teamID,
                summonerName: participant.summonerName,
                perks: participant.perks,
                keystoneID: participant.keystoneID,
            });
            let teamOneIndex = 0;
            let teamTwoIndex = 0;
            event.participants.forEach(participant => {
                if (participant.teamID === 100) {
                    teamOne[participant.accountID] = convertParticipant(participant, teamOneIndex++);
                } else {
                    teamTwo[participant.accountID] = convertParticipant(participant, teamTwoIndex++);
                }
            });
            sendData.teamOne = teamOne;
            sendData.teamTwo = teamTwo;
        }
        break;
        case 'building_destroyed':
            if (event.buildingType !== 'inhibitor' && event.buildingType !== 'turret') return;
            fields.push('turretTier', 'gameTime', 'teamID', 'lastHitter', 'position', 'lane', 'buildingType', 'assistants');
            break;
        case 'turret_plate_destroyed':
            // console.log("turret_plate_destroyed", event);
            fields.push('teamID', 'gameTime', 'lane', 'position');
            break;
        case 'pause_started':
            fields.push('gameTime');
            break;
        case 'pause_ended':
            fields.push('gameTime');
            break;
        case 'champion_kill':
            fields.push('killerTeamID', 'killer', 'victim', 'killStreakLength');
            break;
        case 'champion_kill_special':
            fields.push('killType', 'killer', 'killStreakLength', 'gameTime');
            break;
        case 'champion_level_up':
            fields.push('gameTime');
            break;
        case 'skill_level_up':
            fields.push('gameTime');
            break;
        case 'stats_update':
            fields.push('gameTime', 'participants', 'teams');
            break;
        default:
            // console.log('unhandled events ', event.rfc461Schema, event);
            return;
    }
    fields.forEach(field => {
        sendData[field] = event[field];
    });

    sendData.date = event.rfc460Timestamp;
    sendData.action = event.rfc461Schema;
    sendData.sequenceIndex = event.sequenceIndex;
    sendData.platformID = event.platformID;
    sendData.gameID = event.gameID;
    callback && callback(sendData);

    if (fieldCallbacks) {
        Object.keys(fieldCallbacks).forEach(key => {
            if (sendData[key]) {
                fieldCallbacks[key](sendData[key]);
            }
        });
    }
};

